<template>
<li class="m-2 is-pulled-left has-text-centered"
     @drop.prevent="$emit('endDrag', $event, stop)"     @dragover.prevent   >
     <div class="has-text-left">
    <i class="fas fa-map-marker-alt mr-2 is-pulled-left"></i>

    <div class="has-text-left">
        <a href="" @click.prevent="moveJob('anticipate')" v-if="iidx != 0">
            <i class="fas fa-chevron-left mr-2"></i>
        </a>
			<b v-if="stop.details.action_type == 0" class="mr-2">
                <small >DELIVERS AT {{getTruckName(stop.details.destination, this.$parent.$parent.$parent.warehouses_parameters, 'destination')}}</small>
            </b>
            <b v-else class="mr-2">
                <small>PICKUPS FROM {{getTruckName(stop.details.origin, this.$parent.$parent.$parent.warehouses_parameters, 'origin')}}</small>
            </b>
            <!-- <pre>{{stop}}</pre> -->
        <!-- <span
            v-html="getTruckName(this.stop.details.destination, this.$parent.$parent.$parent.warehouses_parameters)"></span> -->
        <a href="" @click.prevent="moveJob('postpone')" v-if="iidx != truck_job.stops.length-1">
            <i class="fas fa-chevron-right ml-2"></i>
        </a>
        <a @click.prevent="$parent.$parent.start_show_stop_modal(false, stop, truck_job, {}, iidx)" class="is-pulled-right" href="">
            <i class="fas fa-edit px-2"></i>
        </a>
        <!-- <a v-if="stop.status_id == 0" class="is-pulled-right"
                @click.prevent="$emit('delete_truck_stop', stop, truck_job, iidx)"
                href="">
            <i class="fas fa-trash has-text-danger"></i>
        </a> -->
    </div>

		<!-- {{truck_job.edit_mode}} -->
    <!-- <div class="field" v-if="!truck_job.edit_mode">
        <div class="control">
            <div class="select is-small">
                <select @change="$emit('save_truck_job_details', stop.details)" v-model="stop.details.destination">
                    <option>{{$t('select_delivery_location')}}</option>
                    <option 
                        v-for="(location, index) in $parent.$parent.$parent.warehouses_parameters
                            .filter(t => t.type_id == 3 && t.id != truck_job.details.destination)" 
                        :key="index" :value="location.id">{{location.value}}
                    </option>
                </select>
            </div>
        </div>
    </div> -->
    <ul class="has-text-left job-materials-table-wrap custom_scrollbar-1">
        <li v-for="(mat, mix) in stop.Qty" 
                class="has-border p-1 has-background-white" :key="mix">
            <a @click.prevent="$emit('delete_truck_job_material', stop, mat)" 
                class="has-text-danger-dark">
                <i class="fa fa-trash"></i>
            </a>
            {{mat.description}}
            <span class="is-pulled-right ml-3 has-text-weight-bold">
                {{mat.Qty}}
            </span>
        </li>
    </ul>
    </div>
</li>
</template>

<script>
import axios from 'axios'
export default {
    props: ["stop", "truck_job", "iidx"],
    methods:{
        getTruckName(id, list, type){
            var return_string = ""
            if(this.stop.details[type + "_entity"] == "sublocation"){
                
                let a = list.filter(l => l.id == id)
                if(a.length == 1) {
                    let fields = JSON.parse(a[0].form_value)
                    let truck_name = fields[0].value
                    return_string = truck_name
                }
            }
            else if(this.stop.details[type + "_entity"] == "warehouse"){
                let wh = JSON.parse(localStorage.warehouses)
                let caption = ''
                wh.map(w =>{
                    if(w.Id == id)
                        caption = w.Caption
                    return_string = caption
                })
            }
            return (return_string )
		},
        moveJob(direction){
            var c, p, payload
            if(direction == 'anticipate'){
                c = this.truck_job.stops[this.iidx]
                p = this.truck_job.stops[this.iidx-1]
                payload = {
                    "guid_from": c.guid, 
                    "guid_from_sort": this.iidx - 1,
                    "guid_to": p.guid, 
                    "guid_to_sort": this.iidx,
                    
                }

                axios.post(this.$tetris_server + '/sort/truck/stops', payload)
                .then(r => {
                    console.log(r.status)
                });

                console.log('current positions to swap', payload)
                
            }
            else if(direction == 'postpone'){
                c = this.truck_job.stops[this.iidx]
                p = this.truck_job.stops[this.iidx+1]
                payload = {
                    "guid_from": c.guid, 
                    "guid_from_sort": this.iidx + 1,
                    "guid_to": p.guid, 
                    "guid_to_sort": this.iidx,
                    
                }

                axios.post(this.$tetris_server + '/sort/truck/stops', payload)
                .then(r => {
                    if(r.status == 200){
                        console.log("searching ", )
                        // this.$parent.$parent.project_trucks_jobs = [...this.$parent.$parent.project_trucks_jobs.map(e => {
                        //     if(e.guid == this.truck_job.guid){
                        //         e.stops.map(s => {
                        //             if(s.guid == payload.guid_from) s.stop_location_sort = payload.guid_from_sort
                        //             else if(s.guid == payload.guid_to) s.stop_location_sort = payload.guid_to_sort
                        //             return s
                        //         }
                        //         )
                        //     }
                        //     return e
                        // })]
                        // this.$parent.$parent.revaluateSelectedWarehouseJobs()
                    }
                });

                
            }
        },
        getWarehouseName(id){
            let w = {}
            let warehouse_list = JSON.parse(localStorage.warehouses)
            warehouse_list.map(e => {
                if(e.Id == id)
                    w = e
            })
            return w.Caption
        },
    }
}
</script>

<style>

</style>
